:root {
  --conversation-font-size: 14px;
  --font-size: 1rem;
  --font-family: "Roboto Flex", sans-serif;
  --headers-font-family: "Ristretto Pro", "Roboto Flex", sans-serif;
  --headers-font-weight: 500;
  --ion-background-color: #eff4fe;
  --background-color: #eff4fe;
  --button-bg-color: #4a86ff;
  --button-text-color: #ffffff;
  --button-text-transform: uppercase;
  --button-border-radius: 0;
  --button-height: 60px;
  --text-color: #2F4270;
  --link-color: #4a86ff;
  --logo-height: 28px;
  --ion-toolbar-bg: #EEF4FF;

  // Custom LIDL colors
  --chat-single-right-button-bg-color: var(--button-bg-color);
  --chat-single-right-button-fg-color: var(--button-text-color);
  --chat-single-right-button-radius: 0;
  --chat-single-left-button-bg-color: #64748B;
  --chat-single-left-button-fg-color: #ffffff;
  --chat-single-left-button-radius: 0;
  --chat-single-icons-color: var(--text-color); // #EE0F23
  --chat-single-bg-color: #EEF4FF; // #EEF4FF
  --chat-single-action-panel-bg-color: #ffffff;
  --chat-single-border-color: #CCDD;
  --chat-single-border-radius: 5;
  --chat-single-toolbar-border-bg: #CCDD;
  --chat-single-action-panel-separator-height: 1px;
  --chat-single-action-panel-separator-width: 100%;
  --chat-single-action-panel-separator-bg: #CCDD;
  --chat-single-action-panel-separator-radius: 0;

  --chat-multi-message-bg: var(--button-bg-color);
  --chat-multi-message-fg: var(--button-text-color);
  --chat-multi-message-icons-fg: var(--button-text-color);

  --nav-menu-toolbar-bg: var(--ion-toolbar-bg);
  --nav-menu-bg: #EEF4FF;
  --nav-menu-item-fg: initial;
  --nav-menu-item-separator-fg: rgb(203 213 225);
  --nav-menu-credits-count-fg: var(--text-color);

  --user-role-fg: rgb(156 163 175);

  --checkbox-border-radius: 0;
  --checkbox-bg: var(--button-bg-color);

  --toast-bg: #f4f4fa;
  --toast-fg: #4b4a50;
  --toast-radius: 0;

  --del-button-bg-color: #EE0F23;
  --del-button-fg-color: white;

  --input-radius: 0;
  --input-border-bg: rgb(209 213 219);
  --input-bg: #fff;

  --inner-page-headers-fg: var(--headers-color);
  --inner-page-bg: #fff;
  --inner-page-fg: var(--text-color);
  --inner-page-form-bg: var(--bg-color-1);
  --inner-page-form-fg: var(--text-color);
  --inner-page-form-icons-fg: var(--text-color);
  --inner-page-form-icons-bg: #fff;
  --inner-page-form-button-stroke-fg: var(--text-color);
  // END

  --headers-color: var(--text-color);
  --common-font-family: var(--font-family);
  --mdc-filled-text-field-label-text-font: var(--common-font-family);
  --mat-stepper-container-text-font: var(--common-font-family);
  --mat-toolbar-title-text-font: var(--common-font-family);
  --mat-option-label-text-font: var(--common-font-family);
  --mat-optgroup-label-text-font: var(--common-font-family);
  --mat-form-field-container-text-font: var(--common-font-family);
  --mat-select-trigger-text-font: var(--common-font-family);
  --mat-datepicker-calendar-text-font: var(--common-font-family);
  --mat-menu-item-label-text-font: var(--common-font-family);
  --mdc-text-button-label-text-font: var(--headers-font-family);
  --ion-text-color: var(--text-color);
  --icon-color: var(--text-color);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--button-bg-color);
  --mdc-snackbar-supporting-text-color: var(--button-text-color);
  --mdc-snackbar-container-shape: 0;
  --mdc-snackbar-container-color: var(--button-bg-color);
  --mdc-icon-button-icon-color: var(--text-color);
  --mdc-filled-text-field-input-text-color: var(--text-color);
  --mat-select-enabled-trigger-text-color: var(--text-color);
  --mat-option-label-text-color: var(--text-color);
  --mat-menu-item-label-text-color: var(--text-color);
  --ion-font-family: var(--common-font-family);
  --title-font: var(--headers-font-family);
  --mdc-text-button-label-text-size: 20px;
  --mdc-text-button-label-text-weight: 500;
  --mat-select-panel-background-color: #ffffff;
  --placeholder-color: rgba(47, 66, 112, 0.4);
  --social-login-border: 1px solid #EEF4FF;
  --avatar-placeholder-bg-color: #D1F5D1;
  --error-text-color: #F4451D;
  --mdc-filled-text-field-label-text-size: 14px;
  --single-mode-action-panel-height: 123px;
  --multi-mode-action-panel-height: 110px;
  --user-joined-message-bg: #eff4fe;
  --chat-time-color: #64748B;
  --chat-avatar-bg-color: #C2D4F8;
  --chat-avatar-fg-color: var(--button-bg-color);
  --checkbox-bg-color: var(--button-bg-color);
  --chat-multi-mode-bg-color: #ffffff;
  --chat-multi-mode-action-panel-bg-color: #EEF4FF;
  --chat-other-message-bg-color: #EEF4FF;
  --chat-message-date-color: #AFCAFF;
  --bg-color-1: #EEF4FF;
  --footer-menu-color: #8E9BB7;
  --chat-error-bg-color: #F4451D;
  --chat-error-text-color: #ffffff;
  --dialog-close-icon-color: #8B8F98;
  --mdc-text-button-label-text-tracking: 0;
  --mat-datepicker-calendar-date-text-color: var(--text-color);
  --mat-datepicker-calendar-period-button-text-color: var(--text-color);
  --mdc-filled-text-field-input-text-placeholder-color: var(--placeholder-color);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--placeholder-color);
  --mat-form-field-disabled-input-text-placeholder-color: var(--placeholder-color);
  --mat-select-placeholder-text-color: var(--placeholder-color);
  --tariff-prepaid-color: #8B8F98;
  --tariff-premium-color: #C2A746;
  --tariff-corporate-color: #46C2AC;
  --mat-menu-container-color: #EEF4FF;
  --mat-form-field-container-height: 60px;
  --button-red: #F4451D;
  --white: #ffffff;
  --modal-bg-blur: blur(3px);
  --chat-highlight-bg-color: rgba(239, 244, 254, 0.8);
  --ion-border-color: rgb(203 213 225);
  --button-disabled-fg: #b0bad9;
  --ion-toolbar-color: var(--text-color);
  --ion-color-primary: var(--button-bg-color);
  --mdc-filled-text-field-container-shape: 0;
  --mat-menu-item-label-text-size: 14px;
  --mat-dialog-container-small-max-width: 96vw;
  --mat-dialog-container-max-width: 95vw;
  --ion-color-danger: red;
}

.chat-msg {
  font-size: var(--conversation-font-size) !important;
}

[type='text'], input:where(:not([type])), [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  border-color: rgb(203 213 225);
  padding: 0 0.5em;
  box-shadow: none !important;
}

.inner-page__content {
  .title {
    color: var(--inner-page-headers-fg);
  }

  .bg-white {
    background-color: var(--inner-page-bg);
    color: var(--inner-page-fg);

    .title {
      color: var(--inner-page-headers-fg) !important;
    }
  }

  .bg-color-1, &.bg-color-1 {
    background-color: var(--inner-page-form-bg) !important;
    color: var(--inner-page-form-fg) !important;

    .title {
      color: var(--inner-page-form-fg);
    }

    a {
      color: var(--inner-page-form-fg) !important;
    }

    ion-icon {
      color: var(--inner-page-form-icons-fg);
    }

    .social-login {
      background-color: var(--inner-page-form-icons-bg);
    }

    .button.stroked {
      color: var(--inner-page-form-button-stroke-fg);
    }
  }
}

ion-range {
  --bar-background-active: var(--checkbox-bg);
}

ion-header {
  background-color: var(--ion-background-color);
}

ion-toolbar {
  --min-height: 57px;
  --background: var(--ion-toolbar-bg);

  &.editor {
    --border-color: var(--chat-single-toolbar-border-bg);
  }

  &.no-border {
    --border-width: 0 !important;
  }

  &.nav-menu {
    --background: var(--nav-menu-toolbar-bg);
    --border-color: var(--nav-menu-toolbar-bg);
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
  }
}

ion-content {
  &.nav-menu {
    --background: var(--nav-menu-toolbar-bg);
    --border-color: var(--nav-menu-toolbar-bg);
  }
}

ion-title {
  font-family: var(--headers-font-family) !important;
  font-weight: var(--headers-font-weight) !important;
  color: var(--headers-color);
  line-height: 1.2;
  font-size: 1.875rem;
}

ion-input {
  --placeholder-color: #9cadca !important;
  --padding-start: 1em !important;;
  --padding-end: 1em !important;;
  margin-bottom: 1em;
}

.textarea-wrapper-inner {
  @apply h-full;
}

.native-wrapper {
  --padding-top: 0;
}

.label-text {
  @apply text-gray-400;
}

ion-title {
  padding-inline-start: 50px;
  padding-inline-end: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .toolbar-title {
  }
}

.bg-color-1 {
  background-color: var(--bg-color-1) !important;
}

.mat-mdc-text-field-wrapper {
  min-height: 60px;
  display: flex !important;
  align-items: center !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Snack bar
/* -------------------------------------------------------------------------- */
.mat-mdc-snack-bar-container {
  margin: 0 !important;

  &.error {
    .mat-mdc-snackbar-surface {
      color: var(--chat-error-text-color) !important;
      background-color: var(--chat-error-bg-color) !important;
    }
  }

  .mat-mdc-snackbar-surface {
    box-shadow: none !important;
    padding-right: 0 !important;
  }

  .mdc-snackbar__label {
    padding: 17px !important;
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #ffffff !important;

    .dark & {
      color: #000000 !important;
    }
  }
}

ngx-mat-timepicker-face .clock-face {
  width: 280px !important;
  height: 280px !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-135%) scale(0.75) !important;
}

.placeholder {
  color: var(--placeholder-color);
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
  box-shadow: none !important;
}

ion-content::part(scroll) {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: lightgray white;
}

ion-content {
  --background: var(--white);
}

.scanner-dialog-inner {
  background-color: #fff;
  margin: 0% auto !important;
  padding: 20px;
  width: 100% !important;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.close-button {
  z-index: 99999;
  color: var(--text-color) !important;
  float: none !important;
  font-size: 40px !important;
  font-weight: normal !important;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 0;
}

.iv-fullscreen-close {
  top: 50px;
}

ion-toast.custom-toast {
  --background: var(--toast-bg);
  --color: var(--toast-fg);
  --border-radius: var(--toast-radius);
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 9999999;
  top: 57px;
  font-size: var(--font-size);

  &.error {
    --background: #ff0000;
    --color: var(--white);
  }
}

ion-toast.custom-toast::part(message) {
  //font-style: italic;
}

ion-toast.custom-toast::part(button) {
  border-left: 1px solid #d2d2d2;
  color: #030207;
  font-size: 15px;
}

ngx-otp-input {
  .ngx-otp-input-box {
    width: 50px !important;
    height: 55px !important;
    padding: 0.5rem !important;
    font-size: 2.5rem !important;
  }
}

ion-item {
  --inner-border-width: 0;
  --ion-safe-area-right: 0;
  --inner-padding-end: 0;
  --padding-start: 0;
  --ion-safe-area-left: 0;
  --background: var(--white);
}

ion-list {
  --ion-item-background: var(--white);
  --ion-background-color: var(--white);
}

ion-item-options {
  --ion-item-border-color: transparent;
}

html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: var(--button-bg-color);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: var(--button-bg-color);
}

.mat-mdc-button:not(:disabled) {
  color: var(--button-bg-color) !important;
}

.date-picker {
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
    --mdc-filled-text-field-disabled-input-text-color: var(--text-color);
  }

  .mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disabled {
    opacity: 1 !important;
  }

  .mat-mdc-icon-button {
    @apply text-gray-400;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: var(--white);
}

.mdc-text-field--filled.mdc-text-field--disabled {
  --mdc-filled-text-field-disabled-container-color: var(--white);
}

ion-item-option {
  font-size: 16px;
  font-family: var(--mdc-text-button-label-text-font);
  text-transform: var(--button-text-transform);
  min-width: 80px;
  color: var(--button-text-color);
  font-weight: var(--headers-font-weight);
}

@media (orientation: landscape) {
  ion-menu {
    --side-width: 130px;
    --side-min-width: 130px;
    --side-max-width: 130px;
  }
}
