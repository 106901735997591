/* -------------------------------------------------------------------------- */
/*  @ Font smoothing
/* -------------------------------------------------------------------------- */
*[class*='mat-'],
*[class*='mat-mdc-'] {
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;

  * {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Buttons
/* -------------------------------------------------------------------------- */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  line-height: 1 !important;

  /* Large button */
  &.fuse-mat-button-large {
    height: 48px;
    min-height: 48px;
    max-height: 48px;
  }

  /* Lower the icon opacity on disabled buttons */
  &[disabled='true'] {
    .mat-icon {
      opacity: 0.38 !important;
    }
  }
}

/* Icon buttons */
.mat-mdc-icon-button {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  padding: 0 !important;

  svg,
  img {
    height: auto !important;
  }
}

/* FAB buttons */
.mat-mdc-fab {
  max-height: 56px;
  border-radius: 16px !important;

  &:not(.mdc-fab--extended) .mdc-fab__ripple {
    border-radius: 16px !important;
  }
}

/* Mini FAB buttons */
.mat-mdc-mini-fab {
  border-radius: 12px !important;

  &:not(.mdc-fab--extended) .mdc-fab__ripple {
    border-radius: 12px !important;
  }
}

/* Rounded design */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 20px !important;
  border-radius: 9999px !important;
}


/* Fix the alignment of icons when used within buttons */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {

  & > .mat-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/* Adjust the color of mat-progress-spinner when used within buttons */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {

  .mat-mdc-progress-spinner {

    .mdc-circular-progress__indeterminate-container {

      circle {
        stroke: currentColor !important;
        animation-duration: 6000ms;
      }
    }
  }
}

/* Adjust the focus, ripple and icon colors of colored background buttons */
.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {

  --mat-mdc-button-persistent-ripple-color: theme('colors.gray[400]') !important;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1) !important;

  .dark & {
    --mat-mdc-button-persistent-ripple-color: theme('colors.black') !important;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1) !important;
  }

  .mat-icon {
    color: currentColor !important;
  }

}

/* -------------------------------------------------------------------------- */
/*  @ Checkbox
/* -------------------------------------------------------------------------- */
.mat-mdc-checkbox {
  display: inline-flex !important;

  .mdc-form-field {
    padding-right: 12px;
  }
}

.mdc-checkbox__native-control {
  opacity: 0 !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Chip
/* -------------------------------------------------------------------------- */
.mat-mdc-chip {
  font-weight: 500 !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Dialog
/* -------------------------------------------------------------------------- */
.mat-mdc-dialog-container {

  .mdc-dialog__surface {
    border-radius: 16px !important;
    padding: 24px;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Drawer
/* -------------------------------------------------------------------------- */
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Form fields
/* -------------------------------------------------------------------------- */

/* "fill" appearance */
.mat-mdc-form-field.mat-form-field-appearance-fill {

  /* Disabled */
  &.mat-form-field-disabled {
    opacity: 0.7 !important;
  }

  /* Hover */
  &:hover {

    .mat-mdc-form-field-focus-overlay {
      opacity: 0 !important;
    }
  }

  /* Focused */
  &.mat-focused {

    .mat-mdc-form-field-focus-overlay {
      opacity: 0 !important;
    }
  }

  /* Focused and valid fields */
  &.mat-focused:not(.mat-form-field-invalid) {

    /* Border color */
    .mat-mdc-text-field-wrapper {
    }
  }

  /* Remove the default arrow for native select */
  &.mat-mdc-form-field-type-mat-native-select {

    .mat-mdc-form-field-infix {

      select {
        top: auto;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-right: 18px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2364748B' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right -7px center;
        background-size: 24px;

        .dark & {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2397a6ba' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
        }
      }

      &:after {
        display: none;
      }
    }
  }

  /* Default style tweaks and enhancements */
  .mat-mdc-text-field-wrapper {
    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-radius: var(--input-radius);
    border-color: var(--input-border-bg);
    background-color: var(--input-bg);

    /* Adjust the top spacing and overflow when mat-label present */
    &:not(.mdc-text-field--no-label) {
      margin-top: 20px;
      overflow: visible;
    }

    /* Form field */
    .mat-mdc-form-field-flex {
      height: 100%;
      position: relative;
      display: flex;
      align-items: stretch;
      padding: 0 16px;

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-text-prefix {
        padding: 0 !important;

        > .mat-icon {
          margin-right: 12px;
          padding: 0 !important;
        }

        > .mat-mdc-icon-button {
          margin: 0 4px 0 -10px;
        }

        > .mat-mdc-select {
          margin-right: 10px;
        }

        > .mat-datepicker-toggle {
          margin-left: -8px;
        }
      }

      .mat-mdc-form-field-icon-suffix,
      .mat-mdc-form-field-text-suffix {
        padding: 0 !important;

        > .mat-icon {
          margin-left: 12px;
          padding: 0 !important;
        }

        > .mat-mdc-icon-button {
          margin: 0 -10px 0 4px;
        }

        > .mat-mdc-select {
          margin-left: 10px;
        }

        > .mat-datepicker-toggle {
          margin-right: -8px;
        }
      }

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-text-prefix,
      .mat-mdc-form-field-icon-suffix,
      .mat-mdc-form-field-text-suffix {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .mat-mdc-icon-button {
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
        }

        .mat-icon,
        .mat-mdc-icon-button:not([disabled]),
        .mat-mdc-select-value {
        }

        /* Datepicker default icon size */
        .mat-datepicker-toggle-default-icon {
        }

        /* Make mat-select usable as prefix and suffix */
        .mat-mdc-select {
          display: flex;
          align-items: center;

          &:focus {

            .mat-mdc-select-trigger {

              .mat-mdc-select-value {
              }

              .mat-mdc-select-arrow-wrapper {

                .mat-mdc-select-arrow {
                  border-top-color: var(--fuse-primary) !important;
                }
              }
            }
          }

          .mat-mdc-select-trigger {
            display: flex;
            align-items: center;

            .mat-mdc-select-value {
              display: flex;
              max-width: none;

              mat-mdc-select-trigger {

                .mat-icon {
                  margin: 0 !important;
                }
              }
            }

            .mat-mdc-select-arrow-wrapper {
              display: flex;
              align-items: center;
              transform: none;
              margin-left: 4px;

              .mat-mdc-select-arrow {
                min-height: 0;
              }
            }
          }
        }
      }

      /* Infix */
      .mat-mdc-form-field-infix {
        position: static;
        display: flex;
        align-items: center;
        width: 88px;
        min-height: 60px;
        padding: 0;
        border: 0;

        /* Floating label - disable floating action */
        //.mat-mdc-floating-label {
        //    top: -25px !important;
        //    left: 0 !important;
        //    width: 100% !important;
        //    transform: none !important;
        //    pointer-events: auto;
        //    font-weight: 500;
        //    @apply text-default #{'!important'};
        //}

        /* Textarea */
        textarea.mat-mdc-input-element {
          margin: 12px 0;
          padding: 0 6px 0 0;
        }

        /* Chips */
        .mat-mdc-chip-set {
          width: 100%;
          margin: 0 -8px;
        }
      }
    }

    /* Remove the underline */
    .mdc-line-ripple {
      display: none;
    }
  }

  /* Subscript tweaks */
  .mat-mdc-form-field-subscript-wrapper {
    font-size: 12px;
    font-weight: 500;

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0;
    }

    .mat-mdc-form-field-hint {
    }
  }

  /* Adds better alignment for textarea inputs */
  &:has(textarea.mat-mdc-input-element) {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix,
        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          align-self: flex-start;
          padding-top: 14px !important;
        }
      }
    }
  }
}

/* "outline" appearance */
.mat-mdc-form-field.mat-form-field-appearance-outline {

  &:not(.mat-focused):not(.mat-form-field-invalid) {

    .mat-mdc-text-field-wrapper {

      .mat-mdc-form-field-flex {

        .mdc-notched-outline {

          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            @apply border-gray-300;
          }
        }
      }
    }
  }

  /* Remove the extra border on the right side of the notch */
  /* Tailwind's global border setter causes this issue */
  .mat-mdc-text-field-wrapper {

    .mat-mdc-form-field-flex {

      .mdc-notched-outline {

        .mdc-notched-outline__notch {
          border-right-style: none !important;
        }
      }
    }
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Datepicker
/* -------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------- */
/*  @ Icon
/* -------------------------------------------------------------------------- */
.mat-icon {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  font-size: 24px;
  line-height: 24px;
  -webkit-appearance: none !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Inputs
/* -------------------------------------------------------------------------- */
.mat-mdc-input-element {

  &::placeholder {
    transition: none !important;
  }

  &::-moz-placeholder {
    transition: none !important;
  }

  &::-webkit-input-placeholder {
    transition: none !important;
  }

  &:-ms-input-placeholder {
    transition: none !important;
  }
}

/* Invalid */
.mat-form-field-invalid {

  .mat-mdc-input-element {

    /* Placeholder color */
    &::placeholder {
    }

    &::-moz-placeholder {
    }

    &::-webkit-input-placeholder {
    }

    &:-ms-input-placeholder {
    }
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Menu
/* -------------------------------------------------------------------------- */
.mat-mdc-menu-panel {
  min-width: 144px !important;

  .mat-mdc-menu-content {

    .mat-mdc-menu-item {
      .mat-mdc-menu-item-text {
        display: flex;
        align-items: center;
        padding-right: 16px;
      }

      .mat-icon-no-color {
        --tw-text-opacity: 1;
        color: rgba(var(--fuse-mat-icon-rgb), var(--tw-text-opacity));
      }
    }

    /* Divider within mat-menu */
    mat-divider {
      margin: 8px 0;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Paginator
/* -------------------------------------------------------------------------- */
.mat-mdc-paginator {

  .mat-mdc-paginator-container {
    padding: 8px 16px;
    justify-content: space-between;

    @screen sm {
      justify-content: normal;
    }

    /* Page size select */
    .mat-mdc-paginator-page-size {
      align-items: center;
      min-height: 40px;
      margin: 8px;

      .mat-mdc-paginator-page-size-label {
        display: none;
        margin-right: 12px;

        @screen sm {
          display: block;
        }
      }

      .mat-mdc-paginator-page-size-select {
        margin: 0;

        .mat-mdc-text-field-wrapper {
          padding: 0 10px;

          .mat-form-field-flex {
            min-height: 32px;
          }
        }
      }
    }

    /* Range actions */
    .mat-mdc-paginator-range-actions {
      margin: 8px 0;

      .mat-mdc-paginator-range-label {
        margin-right: 16px;
      }
    }
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Select
/* -------------------------------------------------------------------------- */
.mat-mdc-select {
  display: inline-flex !important;

  .mat-mdc-select-placeholder {
    transition: none !important;
  }

  .mat-mdc-select-trigger {

    .mat-mdc-select-value {
      position: relative;
      display: flex;
      max-width: none;

      .mat-mdc-select-value-text {
        display: inline-flex;

        > * {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .mat-mdc-select-arrow-wrapper {
    transform: translateY(0) !important;

    .mat-mdc-select-arrow {
      margin: 0 0 0 8px;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Slide Toggle
/* -------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------- */
/*  @ Snack bar
/* -------------------------------------------------------------------------- */
.mat-mdc-snack-bar-container {
  margin: 0 !important;

  &.error {
    .mat-mdc-snackbar-surface {
      color: var(--chat-error-text-color) !important;
      background-color: var(--chat-error-bg-color) !important;
    }
  }

  .mat-mdc-snackbar-surface {
    box-shadow: none !important;
    padding-right: 0 !important;
  }

  .mdc-snackbar__label {
    padding: 17px !important;
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #ffffff !important;

    .dark & {
      color: #000000 !important;
    }
  }
}


/* -------------------------------------------------------------------------- */
/*  @ Stepper
/* -------------------------------------------------------------------------- */
.mat-step-icon {

  /* Do not override the mat-icon color */
  .mat-icon {
    color: currentColor !important;
  }
}

.mat-step-label,
.mat-step-label-selected {
  font-weight: 500 !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Table
/* -------------------------------------------------------------------------- */
.mat-mdc-table {

  .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
    background: none !important;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Tabs
/* -------------------------------------------------------------------------- */
.mat-mdc-tab-group {

  /* No header */
  &.fuse-mat-no-header {

    .mat-mdc-tab-header {
      height: 0 !important;
      max-height: 0 !important;
      border: none !important;
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }

  &:not(.mat-background-primary):not(.mat-background-accent) {

    .mat-mdc-tab-header {

      .mat-mdc-tab-label-container {
        box-shadow: inset 0 -1px var(--fuse-border);
      }
    }
  }

  .mat-mdc-tab-header {

    .mat-mdc-tab-label-container {
      margin: 0 24px;
    }
  }

  .mat-mdc-tab-body-content {
    padding: 24px;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Textarea
/* -------------------------------------------------------------------------- */
textarea.mat-mdc-input-element {
  box-sizing: content-box !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Tooltip
/* -------------------------------------------------------------------------- */

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: var(--fuse-text-default) !important;
  color: white;

  .dark & {
    background-color: var(--fuse-text-secondary) !important;
    color: var(--fuse-bg-default) !important;
  }
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-size: 1rem !important;
  padding-top: 0.8rem;
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control.mat-datepicker-input, .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control.date-time {
  font-size: 1.9rem !important;
  padding-top: 0;
}
