@font-face {
  font-family: 'Ristretto Pro';
  src: url('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold.eot');
  src: local('RistrettoPro'), local('RistrettoPro'),
    //  url('RistrettoPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold.woff') format('woff'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ristretto Pro';
  src: url('/assets/fonts/ristretto-pro/RistrettoPro-Light.eot');
  src: local('/assets/fonts/ristretto-pro/Ristretto Pro Light'), local('/assets/fonts/ristretto-pro/RistrettoPro-Light'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Light.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Light.woff2') format('woff2'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Light.woff') format('woff'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ristretto Pro';
  src: url('/assets/fonts/ristretto-pro/RistrettoPro-ExtraLight.eot');
  src: local('/assets/fonts/ristretto-pro/Ristretto Pro ExtraLight'), local('/assets/fonts/ristretto-pro/RistrettoPro-ExtraLight'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-ExtraLight.woff2') format('woff2'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-ExtraLight.woff') format('woff'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Ristretto Pro';
  src: url('/assets/fonts/ristretto-pro/RistrettoPro-Black.eot');
  src: local('/assets/fonts/ristretto-pro/Ristretto Pro Black'), local('/assets/fonts/ristretto-pro/RistrettoPro-Black'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Black.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Black.woff2') format('woff2'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Black.woff') format('woff'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Ristretto Pro';
  src: url('/assets/fonts/ristretto-pro/RistrettoPro-Thin.eot');
  src: local('/assets/fonts/ristretto-pro/Ristretto Pro Thin'), local('/assets/fonts/ristretto-pro/RistrettoPro-Thin'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Thin.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Thin.woff2') format('woff2'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Thin.woff') format('woff'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Ristretto Pro';
  src: url('/assets/fonts/ristretto-pro/RistrettoPro-Bold.eot');
  src: local('/assets/fonts/ristretto-pro/Ristretto Pro Bold'), local('/assets/fonts/ristretto-pro/RistrettoPro-Bold'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Bold.woff2') format('woff2'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Bold.woff') format('woff'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ristretto Pro';
  src: url('/assets/fonts/ristretto-pro/RistrettoPro-Medium.eot');
  src: local('/assets/fonts/ristretto-pro/Ristretto Pro Medium'), local('/assets/fonts/ristretto-pro/RistrettoPro-Medium'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Medium.woff2') format('woff2'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Medium.woff') format('woff'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ristretto Pro';
  src: url('/assets/fonts/ristretto-pro/RistrettoPro.eot');
  src: local('/assets/fonts/ristretto-pro/Ristretto Pro'), local('/assets/fonts/ristretto-pro/RistrettoPro'),
  url('/assets/fonts/ristretto-pro/RistrettoPro.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/ristretto-pro/RistrettoPro.woff2') format('woff2'),
  url('/assets/fonts/ristretto-pro/RistrettoPro.woff') format('woff'),
  url('/assets/fonts/ristretto-pro/RistrettoPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ristretto Pro';
  src: url('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold.eot');
  src: local('/assets/fonts/ristretto-pro/Ristretto Pro SemiBold'), local('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold.woff') format('woff'),
  url('/assets/fonts/ristretto-pro/RistrettoPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

// LIDL fonts

@font-face {
  font-display: swap;
  font-family: LidlFontCondPro;
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/lidl-cond-pro/LidlFontCondPro-Regular.woff2) format("woff2"), url(/assets/fonts/lidl-cond-pro/LidlFontCondPro-Regular.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: Lidl Font Pro;
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/lidl-cond-pro/LidlFontPro-Regular.woff2) format("woff2"), url(/assets/fonts/lidl-cond-pro/LidlFontPro-Regular.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: Lidl Font Pro;
  font-style: normal;
  font-weight: 500;
  src: url(/assets/fonts/lidl-cond-pro/LidlFontPro-SemiBold.woff2) format("woff2"), url(/assets/fonts/lidl-cond-pro/LidlFontPro-SemiBold.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: Lidl Font Pro;
  font-style: normal;
  font-weight: 700;
  src: url(/assets/fonts/lidl-cond-pro/LidlFontCondPro-Bold.woff2) format("woff2"), url(/assets/fonts/lidl-cond-pro/LidlFontCondPro-Bold.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: LidlFontCondPro;
  font-style: normal;
  font-weight: 700;
  src: url(/assets/fonts/lidl-cond-pro/LidlFontCondPro-Semibold.woff2) format("woff2"), url(/assets/fonts/lidl-cond-pro/LidlFontCondPro-Semibold.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: LidlFontCondPro;
  font-style: normal;
  font-weight: 800;
  src: url(/assets/fonts/lidl-cond-pro/LidlFontCondPro-Bold.woff2) format("woff2"), url(/assets/fonts/lidl-cond-pro/LidlFontCondPro-Bold.woff) format("woff")
}
