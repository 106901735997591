@import './fonts';
@import './overrides';

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

html {
  height: 100dvh;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  font-size: var(--font-size);
}

body {
  height: 100%;
  font-family: var(--font-family);
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  touch-action: pan-x pan-y;
  overscroll-behavior-y: none;
}

:focus-visible {
  outline: none;
}

.md body, .md body.dark {
  --ion-background-color: #eff4fe;
}

.dir-ltr {
  direction: ltr;
}

.dir-rtl {
  direction: rtl;
}

a {
  color: var(--link-color);
  cursor: pointer;
}

.link-color {
  color: var(--link-color);
}

button[disabled] {
  color: var(--button-disabled-fg) !important;
}

[contenteditable] {
  backface-visibility: hidden;
  -webkit-user-select: text;
  user-select: text;
  outline-width: 0;
  height: 100%;
}

.ql-container {
  height: 100%;
}

.chat-highlight-message {
  color: var(--placeholder-color);
  background-color: var(--user-joined-message-bg);
}

.bg-color-1 {
  background-color: var(--bg-color-1) !important;
}

.normal-text {
  font-family: var(--font-family) !important;
}

.title {
  font-family: var(--headers-font-family) !important;
  font-weight: var(--headers-font-weight) !important;
  color: var(--headers-color);
  line-height: 1.2;

  &.active {
    color: var(--button-bg-color);
  }
}

.title-1 {
  font-family: var(--headers-font-family) !important;
  font-weight: 400 !important;
  line-height: 1.2;

  &.active {
    color: var(--button-bg-color);
  }
}

.title-2 {
  font-family: var(--headers-font-family) !important;
  font-weight: 700 !important;
  font-size: 1.1em;

  &.active {
    color: var(--button-bg-color);
  }
}

[class*=" fa-"], [class^="fa-"], [class*="fa- "], ion-icon {
  &.active {
    color: var(--button-bg-color);
  }
}

.mat-mdc-tooltip-show {
  background-color: #2F4270;
  border-radius: 2px;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.inner-page {
  background-color: #ffffff;
  height: 100dvh;
  width: 100%;

  .finexword-logo {
    margin: 0 auto;

    img {
      margin: 0 auto;
      max-width: 140px;
    }
  }
}

.header {
  font-family: var(--headers-font-family);
  font-weight: 500;
}

.wrap {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.error-text {
  color: var(--error-text-color);
}

.fullscreen {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.button, button.html5-qrcode-element {
  &:not(.in-toolbar) {
    position: relative;
    color: var(--button-text-color);
    font-family: var(--headers-font-family);
    font-weight: var(--headers-font-weight);
    background-color: var(--button-bg-color);
    text-transform: var(--button-text-transform);
    border-radius: var(--button-border-radius) !important;
    font-size: 22px;
    min-width: 6em;
    text-align: center;
    padding: 0.5em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--button-height);

    &.red {
      background-color: var(--del-button-bg-color);
      color: var(--del-button-fg-color);
    }

    &.button-sm {
      font-size: 1em;
      padding: 0.4em 0 0.3em 0;
      height: 30px;
    }

    &.button-m {
      min-width: 80px;
      font-size: 1.2em;
      padding: 0.5em 0 0.4em 0;
      height: 40px;

      &.tiny {
        padding: 0.2em 0;
        height: 32px;
      }

      &.loading {
        &::before {
          width: 2.4px; /* 4px * 0.6 */
          box-shadow: 11.4px 0 0 4.2px, 22.8px 0 0 1.8px, 34.2px 0 0 0; /* Adjusted values */
          transform: translateX(-22.8px); /* 38px * 0.6 */
          animation: l22 0.5s infinite alternate linear;
        }
      }
    }

    &.transparent {
      background: transparent;
    }

    &.stroked {
      background-color: transparent;
      color: var(--text-color);

      &.bordered {
        color: var(--button-bg-color);
        border: 1px solid var(--button-bg-color);

        &.white {
          color: white;
          border: 1px solid white;
        }

        &.loading {
          &::before {
            color: var(--button-bg-color);
          }
        }
      }

      &.borderless {
        border: none !important;
      }

      &.red {
        color: var(--button-red);
        border: 1px solid var(--button-red);

        &.loading {
          &::before {
            color: var(--button-red);
          }
        }
      }
    }

    &.loading {
      font-size: 0;

      &::before {
        content: '';
        display: block;
        color: #ffffff;
        width: 4px;
        aspect-ratio: 1;
        border-radius: 50%;
        box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
        transform: translateX(-38px);
        animation: l21 .5s infinite alternate linear;
      }
    }
  }
}

.menu-bg {
  background-color: var(--nav-menu-bg);
}

.nav-menu__item__border-top {
  border-top: 1px solid;
  border-top-color: var(--nav-menu-item-separator-fg);
}

@keyframes l21 {
  50% {
    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px
  }
  100% {
    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px
  }
}

.lang-code-chip {
  background: #fff;
  border-radius: 0.25rem;
  border: 1px solid #acbaca;
  font-size: 0.8125rem;
  color: #879bb2;
  padding: 0.0625rem 0.375rem;
  min-width: 3rem;
  text-align: center;
  display: inline-block;
  margin-left: 0.375rem;
  max-width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.term-key-wrapper {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(271deg, rgba(76, 185, 83, 0.3) 0%, rgba(0, 203, 195, 0.3) 100%) 1;

  .term-key-form {
    background: #F7FDFF;
    border-color: #B5E0D5;
    color: #333939;
    border-radius: 10px 10px 0 0;
    height: 35px;
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    resize: none;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

.mat-mdc-select-panel-above, .mat-select-search-inner-row, div.mat-mdc-select-panel {
  background: #fff;
}

.mat-mdc-select-panel-above:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.mat-divider.mat-divider-horizontal {
  color: rgba(0, 0, 0, .2);
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: 2em;
  color: var(--dialog-close-icon-color);
}

.close-icon-relative {
  cursor: pointer;
  font-size: 1.5em;
  color: var(--dialog-close-icon-color);
}

.dialog-panel {
  position: fixed !important;
  bottom: 0 !important;
  //transform: translateY(100%);
  //transition: transform 0.2s ease-out;
  .mat-mdc-dialog-container mdc-dialog {
    animation: custom-dialog-enter 0.2 ease-out !important;
  }
}

@keyframes custom-dialog-enter {
  from {
    transform: translate(0);
  }

  to {
    transform: translate(100%);
  }
}

.close {
  font-size: 24px;
  cursor: pointer;
  float: right;
}

.chat-avatar-bg-color {
  background-color: var(--chat-avatar-bg-color);
}

.chat-avatar-fg-color {
  color: var(--chat-avatar-fg-color);
}

.stop-record-button {
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--chat-single-right-button-bg-color);
  color: var(--chat-single-right-button-fg-color);
  border-radius: var(--chat-single-right-button-radius);
  width: 80px;
  min-width: 80px;
  height: 80px;
  overflow: hidden;
  padding: 10px;
  cursor: pointer;

  &.disabled {
    opacity: 0.8;
  }

  &.other {
    background-color: var(--chat-single-left-button-bg-color);
    color: var(--chat-single-left-button-fg-color);
  }

  &.recording {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &_small {
    min-width: 60px;
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
}

.editor {
  &__icon {
    color: var(--chat-single-icons-color);
  }
}

.logo {
  height: var(--logo-height);
  width: auto;
}

.chat-my-msg-bg {
  background-color: var(--chat-multi-message-bg);
  color: var(--chat-multi-message-fg) !important;

  ion-icon {
    color: var(--chat-multi-message-icons-fg);
  }

  .active {
    color: #ffffff;
  }
}

.credits__count {
  color: var(--nav-menu-credits-count-fg);
}

.user__role-text {
  color: var(--user-role-fg);
}

.common-text {
  h1 {
    font-family: var(--headers-font-family);
    margin-top: 0.5em;
    font-size: 2.1em;
    font-weight: 700;
  }

  h2 {
    font-family: var(--headers-font-family);
    margin-top: 0.5em;
    font-size: 1.8em;
    font-weight: 700;
  }

  p {
    padding: 0.5em 0;
  }

  ul, ol {
    padding-left: 1.2em;

    li {
      list-style: disc;
    }
  }

  ol li {
    list-style: auto;
  }
}

.inactive-msg {
  opacity: 0.6;
}

.fuse-drawer-overlay {
  background-color: transparent !important;
  backdrop-filter: blur(10px);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mat-horizontal-stepper-content.mat-horizontal-stepper-content-inactive {
  height: 0 !important;
}

ion-label {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  overflow: hidden;
}

app-realtime-messages,
app-room-participants,
app-join-room-by-code,
app-exit-room-dialog,
app-terms-dialog,
app-messages-history-widget,
app-unblock-users,
app-qr-code-dialog,
app-room-result-selector,
app-qr-code-scanner-dialog,
app-qr-code-scanner-access-dialog,
app-delete-avatar,
app-selectbox-item-selector,
app-image-cropper,
app-room-action-buttons,
app-ai,
app-messages-history,
app-register,
app-login,
app-successful-payment,
app-payment-failed,
app-register-form,
app-welcome,
app-lang-selector,
app-create-summary,
app-room-info,
app-history,
app-tariffs,
app-buy-package,
app-show-summary-dialog,
app-show-summary,
app-show-transcript,
app-show-transcript-dialog,
app-show-protocol,
app-show-protocol-dialog,
app-edit-profile,
app-security,
app-invoices,
app-preference,
app-notifications,
app-room-history,
app-history-filter,
app-summary-history,
app-protocol-history,
app-transcript-history,
app-install-dialog,
app-participants-list,
app-room-messages,
app-profile,
app-share-summary,
app-share-protocol,
app-photo-source-dialog,
app-photo-analyzing-dialog,
app-new-room-dialog,
app-use-promo-code,
app-invite,
app-share-app-qr-code-dialog,
app-delete-account,
app-cancel-subscription,
app-summary-chat,
app-company-register-user {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  flex: 1 0 auto;
}

app-realtime-stream-button {
  width: 100%;
  display: flex !important;
  flex-direction: column;
}

app-portal {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

textarea {
  resize: none;
  overflow-y: auto;
}

.iv-fullscreen-close {
  &:after, &:before {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 1);
  }
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  scrollbar-width: thin;
  scrollbar-color: lightgray white;
  overscroll-behavior-y: contain;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(93, 93, 93, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.spin-animation {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.overflow-y-auto {
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scrollbar-width: thin;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


[dir=ltr] {
  .swiped-left {
    transform: translateX(0%) !important;
  }

  .swiped-right {
    transform: translateX(-100%) !important;
  }
}

[dir=rtl] {
  .swiped-left {
    transform: translateX(100%) !important;
  }

  .swiped-right {
    transform: translateX(0%) !important;
  }

  .button, button.html5-qrcode-element {
    i {
      margin-right: 0 !important;
      margin-left: 0.625rem !important;
    }
  }

  .menu-item {
    .label {
      margin-left: 0;
      margin-right: 0.625rem;
    }
  }

  .close-icon {
    right: unset;
    left: 20px;
  }

  .mat-mdc-menu-item {
    justify-content: flex-end;
  }

  button.mat-mdc-menu-item {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .mat-mdc-menu-item-text {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    padding-right: 0;
  }
}

.z-99999, app-modal {
  z-index: 59000;
}

ion-popover {
  --backdrop-opacity: 0;
  --background: var(--white);
  --box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 59001 !important;

  .popover-arrow::after {
    background: none !important;
  }

  .ion-delegate-host.popover-viewport {
    padding: 6px !important;
    font-size: 12px !important;
  }
}

@keyframes l22 {
  50% {
    box-shadow: 11.4px 0 0 1.8px, 22.8px 0 0 4.2px, 34.2px 0 0 1.8px; /* Adjusted values */
  }
  100% {
    box-shadow: 11.4px 0 0 0, 22.8px 0 0 1.8px, 34.2px 0 0 4.2px; /* Adjusted values */
  }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  ion-modal {
    --width: 600px !important;
    --height: 800px !important;
  }
}
